import React, { FC, useEffect, useState } from 'react';
import { formatAPITimestamp } from '../../../../utils/dates';
import { Api } from '../../../../services/Api';
import { Page } from '../../../../declarations/models/Page';
import { EventPageItem, EventsAutoBlock, EventsManualBlock } from '../../../../declarations/models/blocks/EventsBlocks';
import { calculateObjectPosition } from '../../../../components/Image';

interface EventsBlockPreviewProps {
  item?: EventPageItem;
  autoPage?: Page;
  block: EventsAutoBlock | EventsManualBlock;
}

export const EventsBlockPreview: FC<EventsBlockPreviewProps> = ({ item, autoPage, block }) => {
  const [event, setEvent] = useState<Page>();

  useEffect(() => {
    if (item?.id) {
      const ctx = Api.getPageSimple(item.id);
      ctx
        .fetchDirect(null)
        .then((p) => !!p && setEvent(p))
        .finally(ctx.abort);
    } else if (autoPage) setEvent(autoPage);
  }, [item?.id, autoPage]);

  return (
    <div
      key={item?.id}
      className={`module  module--card module--${item?.local?.highlight ? 'medium' : 'small'} status--${
        event?.status
      }`}>
      <div
        className={`module__grid ${
          item?.local?.skin ? block?.skin || item?.local?.skin : block?.skin || event?.event_data?.skin || 'skin'
        }`}>
        <div className='module__media'>
          <div className='module__tags'>{block.displayCategory && <div className='module__tag'>(....)</div>}</div>
          {event?.image_src && (
            <img
              style={{
                objectPosition: calculateObjectPosition(event?.image_focus_point?.x, event?.image_focus_point?.y),
              }}
              src={`${event?.image_src}?&dimension=600x600`}
              alt=''
            />
          )}
          {!event?.image_src && <span className='placeholderimg'>&nbsp;</span>}
        </div>
        <div className='module__content'>
          <div className='module__head'>
            <h3 className='card-title'>{item?.local?.title || event?.title}</h3>
          </div>
          <div className='module__body'>
            {block.displayDescription ? <p>{event?.description || item?.local?.description}</p> : null}
          </div>
          <div className='module__foot'>
            <strong className='meta__label meta--location'>
              {block.displayLocation && event?.event_data?.additional_location_info && (
                <span>{event?.event_data?.additional_location_info} </span>
              )}
              {block.displayMuseum && <span>{event?.site_name}</span>}
              {block.displayLocation && <span>{event?.location?.title}</span>}
            </strong>
            {event?.event_data?.time_periods?.map((tp, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className='meta__label meta--date'>
                  {formatAPITimestamp(tp.start_date, 'date')}
                  {tp.end_date ? `- ${formatAPITimestamp(tp.end_date, 'date')}` : null}
                  {tp.start_time ? ` ${formatAPITimestamp(tp.start_time, 'time')}` : null}
                  {tp.end_time ? ` - ${formatAPITimestamp(tp.end_time, 'time')}` : null}
                </div>
              );
            })}
            <div className='meta__label meta--clock'>{event?.event_data?.time_info}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsBlockPreview;
