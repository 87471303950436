import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlockClipboardContext } from '@/editor/lib/BlockClipboardContext';
import { Button } from '@mui/material';
import Container from '@/components/Container';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BlockType } from '../../declarations/models/BlockType';
import { BlockIcon } from '../../components/BlockIcon';
import ButtonCollection, { addButtonSx } from '../../components/ButtonCollection';
import { BaseBlock } from '../../declarations/models/blocks/BaseBlock';
import { BlockModelFactory } from '../lib/factories/BlockModelFactory';
import { useEditorConfiguration } from '../lib/configuration/EditorConfigurationContext';
import { useCurrentSection } from './CurrentSectionProvider';

export interface AddBlockToPageSectionButtonGroupProps {
  onAddBlock: (block: BaseBlock) => void;
  onPaste?: () => void;
}

export const AddBlockButton: FC<AddBlockToPageSectionButtonGroupProps> = ({ onAddBlock, onPaste }) => {
  const { t } = useTranslation('common');
  const { sectionType, section } = useCurrentSection();
  const config = useEditorConfiguration();

  const hiddenBlocks = config.getHiddenBlocks(sectionType);

  const { currentClipboard } = useBlockClipboardContext();

  const handleAddBlock = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, blockType: BlockType) =>
    onAddBlock(BlockModelFactory.makeBlockModel(blockType));
  const getLabel = (blockType: BlockType) => t(`BlockType.${blockType}`);
  const getIcon = (blockType: BlockType) => <BlockIcon blockType={blockType} />;
  const getButtonColor = (blockType: BlockType) => {
    if (
      [
        BlockType.DM_AUTO,
        BlockType.DM_SINGLE,
        BlockType.DM_SINGLE,
        BlockType.DM_MANUAL,
        BlockType.KP,
        BlockType.KP_OWNER,
      ].includes(blockType)
    ) {
      return Styles.Colors.LIGHTEST_BLUE;
    }

    if ([BlockType.EVENTS_AUTO, BlockType.EVENTS_MANUAL].includes(blockType)) {
      return Styles.Colors.STRONG_GREEN_TRANSPARENT;
    }
  };

  const canPaste = currentClipboard && config.isBlockAvailableInSection(sectionType, currentClipboard.value.type);

  return (
    <>
      {section?.introSection && (
        <Container
          column
          fullWidth
          p={2}
          mt={4}
          mb={0}
          gap={0}
          left
          sx={{
            backgroundColor: Styles.Colors.LIGHT_BLUE,
            borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
            border: `1px solid ${Styles.Colors.MEDIUM_BLUE}`,
            fontStyle: 'italic',
            fontSize: '15px',
          }}>
          {t('IntroSection.limitations')}
        </Container>
      )}
      <ButtonCollection
        title={t('add')}
        buttons={[...config.getBlocksAvailableInSection(sectionType)]}
        onClick={handleAddBlock}
        getButtonKey={String}
        getButtonLabel={getLabel}
        getButtonIcon={getIcon}
        getButtonColor={getButtonColor}
        getIsHideable={(blockType) => hiddenBlocks.includes(blockType)}>
        {canPaste && (
          <Button
            color='secondary'
            onClick={onPaste}
            sx={{
              ...addButtonSx,
              backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHT,
              '&:hover': {
                backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHTEST,
              },
            }}>
            <MaterialSymbol name='content_paste' />
            {t('pasteName', { name: t(`BlockType.${currentClipboard?.value.type}`) })}
          </Button>
        )}
      </ButtonCollection>
    </>
  );
};

export default AddBlockButton;
