import React, { FC, useEffect, useState } from 'react';
import { FormControlLabel, FormLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import Container from '../../../../components/Container';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { CardModuleBlock } from '../../../../declarations/models/blocks/CardModuleBlock';

export const CardModuleBlockMetadataFormatSelector: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t: tComponents } = useTranslation('components');
  const {
    field: { value: currentMetadataFormat, onChange: onChangeCurrentMetadataFormat },
  } = useController<BlockSpecificPage<CardModuleBlock>, `${typeof blockPath}.settings.metadataformat`>({
    name: `${blockPath}.settings.metadataformat`,
  });

  const [location, setLocation] = useState<boolean>(currentMetadataFormat?.toString()?.includes('LOCATION'));
  const [category, setCategory] = useState<boolean>(currentMetadataFormat?.toString()?.includes('CATEGORY'));
  const [events, setEvents] = useState<boolean>(currentMetadataFormat?.toString()?.includes('EVENTS'));
  const [footerText, setFooterText] = useState<boolean>(currentMetadataFormat?.toString()?.includes('FOOTERTEXT'));
  const [footerLocation, setFooterLocation] = useState<boolean>(
    currentMetadataFormat?.toString()?.includes('FOOTERLOC'),
  );
  const [noMetaData, setNoMetaData] = useState<boolean>(currentMetadataFormat?.toString()?.includes('false'));

  useEffect(() => {
    let newMetadataFormat = `${location ? '[LOCATION]' : ''} ${category ? '[CATEGORY]' : ''} ${
      footerText ? '[FOOTERTEXT]' : ''
    } ${footerLocation ? '[FOOTERLOC]' : ''}  ${events ? '[EVENTS]' : ''}`.trim();

    if (noMetaData) {
      newMetadataFormat = 'false';
      onChangeCurrentMetadataFormat(newMetadataFormat);
      setLocation(false);
      setCategory(false);
      setEvents(false);
      setFooterText(false);
      setFooterLocation(false);
    }
    onChangeCurrentMetadataFormat(newMetadataFormat);
  }, [location, category, events, noMetaData, footerText, footerLocation, onChangeCurrentMetadataFormat]);

  return (
    <Container column left fullWidth px={0}>
      <FormControlLabel
        control={
          <Switch
            color='secondary'
            checked={noMetaData}
            onChange={(_e, checked) => {
              setNoMetaData(checked);
            }}
          />
        }
        label={tComponents('CardModuleBlock.noMeta')}
      />

      {!noMetaData && (
        <Container column left fullWidth>
          <FormLabel>{tComponents('CardModuleBlock.metadataformat')}</FormLabel>
          <Container left fullWidth>
            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={location}
                  onChange={(_e, checked) => {
                    setLocation(checked);
                  }}
                />
              }
              label={tComponents('CardModuleBlock.location')}
            />

            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={footerLocation}
                  onChange={(_e, checked) => {
                    setFooterLocation(checked);
                  }}
                />
              }
              label={tComponents('CardModuleBlock.footerLocation')}
            />
            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={footerText}
                  onChange={(_e, checked) => {
                    setFooterText(checked);
                  }}
                />
              }
              label={tComponents('CardModuleBlock.footerText')}
            />

            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={category}
                  onChange={(_e, checked) => {
                    setCategory(checked);
                  }}
                />
              }
              label={tComponents('CardModuleBlock.category')}
            />

            <FormControlLabel
              control={
                <Switch
                  color='secondary'
                  checked={events}
                  onChange={(_e, checked) => {
                    setEvents(checked);
                  }}
                />
              }
              label={tComponents('CardModuleBlock.events')}
            />
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default CardModuleBlockMetadataFormatSelector;
