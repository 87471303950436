import React, { FC } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { GenericMedia } from '@/declarations/GenericMedia';
import { GridViewEmployeeVariant } from '@/components/MediaList/ViewTypes/GridView/GridViewEmployeeVariant';
import { CommonMediaListViewTypeProps } from '../../CommonMediaListViewTypeProps';
import Styles from '../../../../assets/js/Styles';
import GridViewDefaultVariant from './GridViewDefaultVariant';
import GridViewDMVariant from './GridViewDMVariant';

export type GridViewProps = CommonMediaListViewTypeProps & {
  renderGridItemContent?: (item: GenericMedia<unknown>) => React.ReactNode;
};

export const GridView: FC<GridViewProps> = ({
  items,
  isSelectable,
  isFavouriteable,
  getIsFavourite,
  getIsSelected,
  onFavouriteChanged,
  onSelectChanged,
  variant = 'default',
  onItemClick,
  gridThumbnailWidth = 300,
  ...rest
}) => {
  if (onItemClick && isSelectable && onSelectChanged) {
    // eslint-disable-next-line no-console
    console.warn('[GridView] onItemClick and onSelectChanged are both supplied. onItemClick will be ignored.');
  }
  return (
    <Box
      role='list'
      gap={1}
      sx={{
        display: 'grid',
        p: 2,
        gridTemplateColumns: `repeat(auto-fill, ${gridThumbnailWidth}px)`,
      }}>
      {items.map((item) => {
        const isSelected = !!isSelectable && !!getIsSelected?.(item);
        const isFavourite = !!isFavouriteable && !!getIsFavourite?.(item);

        const variantProps = {
          item,
          isFavourite,
          isSelectable,
          isFavouriteable,
          getIsFavourite,
          getIsSelected,
          onFavouriteChanged,
          onSelectChanged,
          variant,
          onItemClick,
        };

        const onClick = () => {
          if (isSelectable && onSelectChanged) {
            onSelectChanged(item, !isSelected);
          }
          onItemClick?.(item);
        };
        const isClickable = isSelectable || !!onItemClick;

        return (
          <Box
            key={item.id}
            aria-label={item.title}
            role='listitem'
            sx={{
              width: gridThumbnailWidth,
              height: 'fit-content',
              border: '3px solid transparent',
              outline:
                isSelectable && isSelected
                  ? `4px solid ${Styles.Colors.MEDIA_ITEM_SELECTED_COLOR}`
                  : `0px solid transparent`,
              borderRadius: Styles.Dimensions.RADIUS_ROUND_SM,
              transition: 'all 150ms ease',
              justifyContent: 'center',
              backgroundColor: variant === 'dm' ? Styles.Colors.THEME_BG_COLOR_SECONDARY : undefined,
            }}>
            <ButtonBase
              onClick={onClick}
              aria-label={item.title}
              disabled={!isClickable}
              focusRipple
              style={{
                pointerEvents: isClickable || isFavouriteable ? 'auto' : 'none',
              }}
              sx={{ width: '100%', height: '100%' }}>
              {variant === 'dm' && <GridViewDMVariant {...variantProps} />}
              {variant === 'employee' && <GridViewEmployeeVariant {...variantProps} />}
              {variant !== 'dm' && variant !== 'employee' && <GridViewDefaultVariant {...variantProps} />}
            </ButtonBase>
            {rest.renderGridItemContent?.(item)}
          </Box>
        );
      })}
    </Box>
  );
};

export default GridView;
