/* istanbul ignore file */
// noinspection SpellCheckingInspection

export enum BlockType {
  /**
   * @deprecated ?
   */
  ADD_CARD = 'article__add_card', // FIXME: This is actually the same as `CARD`, but cannot be the same text
  ARTICLE_TITLE = 'article__title',
  ATTACHMENT = 'article__attachment',
  /**
   * @deprecated
   */
  BANNER = 'article__module', // article__module should never be added in admin 2, but should still be supported for old pages
  BYLINE = 'article__byline',
  /**
   * @deprecated
   */
  CALENDAR = 'calendar',
  /**
   * @deprecated ?
   */
  CALENDAR_NAVIGATOR = 'calendar__navigator',
  CARD = 'article__card',
  CARD_SEARCH = 'cardSearch',
  DATELINE = 'article__dateline',
  /**
   * @deprecated ?
   */
  DIGITALT_MUSEUM = 'digitaltMuseum',
  DM_AUTO = 'dm_auto',
  DM_MANUAL = 'dm_manual',
  DM_SINGLE = 'dm_single',
  FORM = 'form',
  FRONTPAGE = 'frontpage__module',
  /**
   * @deprecated ?
   */
  KULTUR_PUNKT = 'kulturpunkt',
  KP = 'kp',
  KP_OWNER = 'kp_owner',
  MAP = 'map',
  MAP_V2 = 'map_v2',
  MEDIA = 'article__media',
  /**
   * @deprecated ?
   */
  MEMBER = 'member',
  OPENING_HOURS = 'article__openinghours',
  PAGE_LIST_AUTO = 'page_list_auto',
  PAGE_LIST_MANUAL = 'page_list_manual',
  /**
   * @deprecated ?
   */
  PERSON = 'article__person',
  /**
   * @deprecated ?
   */
  PRODUCT_STATUS = 'productstatus',
  PROGRAM = 'article__program',
  QUOTE = 'article__quote',
  REFERENCES = 'article__references',
  RELATIONS = 'article__relations',
  SEARCH = 'search',
  SHARING = 'article__sharing',
  TABLE = 'article__table',
  TEXT = 'article__bodytext',
  /**
   * @deprecated ?
   */
  TICKET = 'ticket',
  /**
   * @deprecated ?
   */
  TICKET_VALIDATION = 'ticket_validation',
  /**
   * @deprecated ?
   */
  TIMELINE = 'timeline',
  WIDGET = 'flexible',
  HEADER_MENU = 'menu', // settings block type
  FOOTER_ADDRESS = 'footer__address', // settings block type
  FOOTER_SOCIAL = 'footer__social', // settings block type
  FOOTER_INFO = 'footer__info', // settings block type
  FOOTER_MENU = 'footer__menu', // settings block type
  EMPLOYEES_AUTO = 'employees_auto',
  EMPLOYEES_MANUAL = 'employees_manual',
  EVENTS_AUTO = 'events_auto',
  EVENTS_MANUAL = 'events_manual',
}
