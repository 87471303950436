import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { Card } from '@/declarations/models/Card';
import { Skin } from '@/declarations/models/Skin';
import { Api } from '@/services/Api';
import Container from '@/components/Container';
import { CardPreview } from '@/components/CardPreview';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from '@/components/Loader';
import CardEditor from '@/editor/CardEditor/CardEditor';
import FullScreenModal from '@/components/FullScreenModal';
import { Tag } from '@/declarations/models/Tag';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useSectionsController } from '@/components/Section/SectionsController';
import { SubscriptionLog } from 'rxjs/internal/testing/SubscriptionLog';

export const CardSectionId = 'page-editor-cards-section';

export const ListCardsForPage: FC<{
  siteId: number;
  pageId?: number | null | undefined;
  skins?: Skin[];
  categories?: Tag[];
  locale?: string | null;
}> = ({ siteId, pageId, skins, categories, locale }) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [cards, setCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasFetchedInitialCards, setHasFetchedInitialCards] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmDialog();
  const sectionsController = useSectionsController();

  const [cardId, setCardId] = useState<number>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<Date>();

  const onCreateCardClick = () => {
    setCardId(undefined);
    setEditModalOpen(true);
  };

  const handleItemDelete = async (item: Card) => {
    const id = Number(item.id);
    if (!Number.isNaN(id) && (await confirm(tComponents('MediaView.ConfirmDelete')))) {
      const [, error] = await Api.deleteBlock(siteId, id).fetch();
      if (error) enqueueSnackbar(error.message, { variant: 'error' });
      else enqueueSnackbar('Deleted item', { variant: 'success' });
      setForceRefresh(new Date());
    }
  };

  useEffect(() => {
    const expanded = sectionsController?.isExpanded(CardSectionId) ?? true;
    if (!expanded) {
      return;
    }
    let unmounted = false;
    let ctx: ReturnType<typeof Api.getPageBlocksV2> | null = null;
    if (pageId) {
      setLoading(true);
      ctx = Api.getPageBlocksV2(pageId, {
        block_type: 'article__card',
      });
      ctx
        .fetchDirect(null)
        .then((response) => {
          if (response && !unmounted) {
            setCards(response.items as Card[]);
            setHasFetchedInitialCards(true);
          }
        })
        .finally(() => {
          if (!unmounted) {
            setLoading(false);
          }
        });
    }
    return () => {
      unmounted = true;
      ctx?.abort();
    };
  }, [locale, pageId, forceRefresh, sectionsController, hasFetchedInitialCards]);

  if (!locale) {
    return (
      <Container left wrap gap={2}>
        <Typography fontWeight='bold' mt={2}>
          {tComponents('ListCardsForPage.Title')}
        </Typography>
        <Typography>{tComponents('ListCardsForPage.NoLocale')}</Typography>
      </Container>
    );
  }

  return (
    <Container left wrap gap={2}>
      <FullScreenModal modalOpen={editModalOpen}>
        <CardEditor
          siteId={siteId}
          cardId={cardId}
          pageId={pageId!}
          onCloseEditor={() => setEditModalOpen(false)}
          skins={skins}
          categories={categories}
          onAfterSave={() => {
            setForceRefresh(new Date());
          }}
          defaultLocale={locale}
        />
      </FullScreenModal>
      {loading && <Loader />}

      {/* todo: create HelpTextComponent for displaying migration steps from 1.0 to 2.0 
      <Container
        fullWidth
        p={2}
        mt={2}
        left
        sx={{
          backgroundColor: Styles.Colors.LIGHT_BLUE,
          borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
          border: `1px solid ${Styles.Colors.MEDIUM_BLUE}`,
          fontStyle: 'italic',
        }}>
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: tComponents('Migration.Cards.SectionHelpText') }} />
      </Container>
      */}

      <Container fullWidth left wrap gap={2}>
        {cards.length === 0
          ? tCommon('noContent')
          : cards.map(
              (card) =>
                card.block_type === 'article__card' && (
                  <CardPreview
                    size='small'
                    key={card.id}
                    image={card.mediaobject || card.content?.mediaobject}
                    selectedSkin={skins?.find((s) => s.class === card.content?.skin)}
                    title={card.title}
                    description={`${card?.content?.descr?.slice(0, 100)}...`}>
                    <Container right mt={2}>
                      <Button
                        variant='outlined'
                        size='small'
                        startIcon={<MaterialSymbol name='edit' fill sx={{ fontSize: 'small' }} />}
                        onClick={() => {
                          setCardId(card.id);
                          setEditModalOpen(true);
                        }}>
                        {tComponents('CardEditor.EditCard')}
                      </Button>

                      <Button onClick={() => handleItemDelete?.(card)} endIcon={<MaterialSymbol name='delete' fill />}>
                        {tCommon('delete')}
                      </Button>
                    </Container>
                  </CardPreview>
                ),
            )}
      </Container>

      <Button
        variant='contained'
        color='success'
        startIcon={<MaterialSymbol name='add' />}
        onClick={onCreateCardClick}
        type='button'>
        {tComponents('CardsView.CreateCard')}
      </Button>
    </Container>
  );
};
