import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useWatch } from 'react-hook-form';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import 'dayjs/locale/nb';
import 'dayjs/locale/en-gb';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SkinSelectGroup } from '@/components/SkinSelectGroup';
import { Skin } from '@/declarations/models/Skin';
import { useControlledFieldArray } from '@/hooks/useControlledFieldArray';
import { EventDataTimePeriod } from '@/editor/PageEditor/EventDataTimePeriod';
import CheckboxInput from '@/components/forms/CheckboxInput';
import Container from '../../components/Container';
import { Page } from '../../declarations/models/Page';
import Styles from '../../assets/js/Styles';
import { EventCategory, SubCategory } from '../../declarations/Category';
import TextInput from '../../components/forms/TextInput';
import { useStore } from '../../components/store/Store';
import { Api } from '../../services/Api';
import { Location } from '../../declarations/models/Location';
import SelectInput from '../../components/forms/SelectInput';
import { WeekDay } from '../../declarations/WeekDay';
import { useDebounce } from '../../hooks/useDebounce';
import { isValidUrl } from '../../utils/url';

export const EventData: FC = () => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const [locations, setLocations] = useState<Array<Location>>();
  const hasInitializedTimePeriods = useRef(false);

  useEffect(() => {
    if (siteId) {
      const ctx = Api.getSiteLocations(siteId);
      ctx
        .fetchDirect(null)
        .then((l) => !!l && setLocations(l))
        .finally(ctx.abort);
    }
  }, [siteId]);

  const {
    field: { value: subCategories, onChange: setSubCategories },
  } = useController<Page, 'sub_categories'>({ name: 'sub_categories' });

  const {
    field: { value: useEndDate, onChange: setUseEndDate },
  } = useController<Page, 'event_data.use_end_date'>({ name: 'event_data.use_end_date' });

  const useRange = useWatch<Page, 'event_data.use_range'>({ name: 'event_data.use_range' });

  useEffect(() => {
    if (useRange) setUseEndDate(true);
  }, [useRange]);

  const {
    field: { value: startDates, onChange: setStartDates },
  } = useController<Page, 'event_data.start_dates'>({ name: 'event_data.start_dates', defaultValue: [] });

  const {
    field: { value: endDates, onChange: setEndDates },
  } = useController<Page, 'event_data.end_dates'>({ name: 'event_data.end_dates', defaultValue: [] });

  const {
    fields: timePeriods,
    append: appendTimePeriod,
    remove: removeTimePeriod,
  } = useControlledFieldArray<Page, `event_data.time_periods`, 'itemHash'>({
    name: `event_data.time_periods`,
  });

  useEffect(() => {
    if (hasInitializedTimePeriods.current) {
      return;
    }

    if (!timePeriods || !timePeriods.length) {
      appendTimePeriod({});
    }

    hasInitializedTimePeriods.current = true;
  }, []);

  const {
    field: { value: ticketUrl, onChange: setTicketUrl },
  } = useController<Page, 'event_data.ticket_url'>({ name: 'event_data.ticket_url' });
  const [url, setUrl] = useState<string>(ticketUrl ?? '');
  const [validUrl, setValidUrl] = useState<boolean>(true);
  useDebounce(200, url, (v?: string) => {
    if (isValidUrl(v)) {
      setTicketUrl(v);
      setValidUrl(true);
    } else {
      setTicketUrl('');
      setValidUrl(!v);
    }
  });

  const {
    field: { value: skinValue, onChange: setSkin },
  } = useController<Page, 'event_data.skin'>({ name: 'event_data.skin' });

  const onSkinChange = (skin: Skin | null) => {
    let _skin = skin?.class;
    if (_skin === skinValue) {
      _skin = '';
    }
    setSkin(_skin);
  };

  const {
    field: { value: ticketText, onChange: setTicketText },
  } = useController<Page, 'event_data.ticket_text'>({ name: 'event_data.ticket_text' });

  const {
    field: { value: locationInfo, onChange: setLocationInfo },
  } = useController<Page, 'event_data.additional_location_info'>({ name: 'event_data.additional_location_info' });

  const selectedLocation = useWatch<Page, 'location'>({ name: 'location' });

  const handleSubCategoryChecked = (checked: boolean, category: SubCategory) => {
    const tempArr = subCategories ?? [];
    const i = tempArr?.indexOf(category);
    if (checked && i < 0) setSubCategories([...tempArr, category]);
    else if (!checked && i >= 0) setSubCategories([...tempArr.slice(0, i), ...tempArr.slice(i + 1)]);
  };

  const addTimePeriod = () => {
    appendTimePeriod({
      start_date: '',
    });
  };

  return (
    <Container gap={2} column top left fullWidth>
      <Container
        column
        left
        fullWidth
        sx={{ borderRadius: 1, padding: 2, backgroundColor: Styles.Colors.THEME_PRIMARY }}>
        <FormControl>
          <FormLabel sx={{ color: 'rgba(0, 0, 0, 0.6) !important' }}>{tComp('EventData.SubCategoryLabel')}</FormLabel>
          <FormGroup row>
            {Object.values(EventCategory).map((c) => (
              <FormControlLabel
                key={c}
                control={
                  <Checkbox
                    onChange={(e) => handleSubCategoryChecked(e.target.checked, c)}
                    checked={(subCategories ?? []).includes(c)}
                  />
                }
                label={tCommon(`SubCategory.${c}`)}
              />
            ))}
          </FormGroup>
        </FormControl>
        <Alert sx={{ width: '100%' }} severity='info'>
          {tComp('EventData.SubCategoryInfo')}
        </Alert>
      </Container>
      {timePeriods.map((timePeriod, i) => (
        <EventDataTimePeriod key={timePeriod.id} index={i} onRemove={() => removeTimePeriod(i)} />
      ))}
      <Button
        onClick={addTimePeriod}
        startIcon={<MaterialSymbol name='add' />}
        variant='outlined'
        sx={{ minWidth: 'fit-content', marginBottom: '10px' }}>
        {tComp('EventData.AddTime')}
      </Button>
      <CheckboxInput path='event_data.expanded_dates' label={tComp('EventData.ShowAsOneCardPerDateRow')} />
      {useRange && (
        <SelectInput
          path='event_data.week_days'
          label='week days'
          multiSelect
          options={Object.values(WeekDay)}
          getOptionKey={(o) => o}
          getOptionLabel={(o) => tCommon(`WeekDay.${o}`)}
        />
      )}
      {timePeriods.length > 1 && (
        <TextInput path='event_data.time_info' label={tComp('EventData.TimeInfoLabel')} defaultValue='' size='small' />
      )}
      <Divider flexItem />
      <Container fullWidth column gap={2}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 1,
          }}>
          <Box
            sx={{
              flexGrow: 5,
              maxWidth: '60%',
            }}>
            {locations && (
              <Tooltip title={tComp('EventData.LocationTooltip')} arrow>
                <Container fullWidth>
                  <SelectInput
                    // hack(MV-737): 'location' is (only sometimes...) reset to the original when it gets removed
                    //  solution: key the component with the selected location.
                    //  unfortunately this breaks focus when selection changes, so keyboard users will get mad
                    //  todo: find a proper solution
                    key={selectedLocation?.id ?? 0}
                    options={locations}
                    getOptionKey={(l) => (l ? `${l.id},${l.id}` : '')}
                    getOptionLabel={(l) => String(l?.title)}
                    path='location'
                    label={tComp('EventData.Location')}
                    disabled={!locations.length}
                    defaultValue=''
                    size='small'
                  />
                </Container>
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}>
            <TextField
              variant='outlined'
              fullWidth
              onChange={(e) => setLocationInfo(e.target.value)}
              label={tComp('EventData.LocationText')}
              size='small'
              value={locationInfo}
            />
          </Box>
        </Box>
        <TextInput
          path='description'
          label={tComp('EventData.Description')}
          multiline
          minRows={2}
          maxRows={8}
          size='small'
        />

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 1,
          }}>
          <Box
            sx={{
              flexGrow: 5,
              maxWidth: '70%',
            }}>
            <TextField
              label={tComp('EventData.TicketUrl')}
              variant='outlined'
              fullWidth
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              error={!validUrl}
              size='small'
              helperText={!validUrl ? tComp('EventData.InvalidUrl') : undefined}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}>
            <TextField
              variant='outlined'
              fullWidth
              onChange={(e) => setTicketText(e.target.value)}
              label={tComp('EventData.TicketText')}
              size='small'
              value={ticketText}
            />
          </Box>
        </Box>
      </Container>
      <SkinSelectGroup
        label={tComp('EventData.SkinLabel')}
        availableSkins={state?.selectedSiteSkins ?? []}
        onSkinChange={(skin) => onSkinChange(skin)}
        selectedSkin={(state?.selectedSiteSkins ?? []).find((s) => s.class === skinValue)}
      />
    </Container>
  );
};

export default EventData;
