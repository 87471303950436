import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { ChildrenProp } from '@/declarations/ChildrenProp';
import Container from '@/components/Container';
import {
  Icon,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Chip,
  FormControlLabel,
  ListItemAvatar,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ToolbarHeader } from '@/components/ToolbarHeader';
import { useTranslation } from 'react-i18next';
import { Api } from '@/services/Api';
import { useStore } from '@/components/store/Store';
import MediaList from '@/components/MediaList/MediaList';
import { GenericMedia } from '@/declarations/GenericMedia';
import { Card } from '@/declarations/models/Card';
import { ViewType } from '@/declarations/ViewType';
import { useApi } from '@/hooks/useApi';
import { TagType } from '@/declarations/models/TagType';
import Styles from '@/assets/js/Styles';
import PlaceholderImage from '@/assets/images/broken_image.png';
import Grid from '@mui/material/Grid';
import { noWrapTextStyle } from '@/views/MediaView/MediaViewTypes/ListViewItem';
import { Skin } from '@/declarations/models/Skin';
import { SortDirection, SortOption, SortType } from '@/declarations/models/SortOption';
import FullScreenModal from '@/components/FullScreenModal';
import CardEditor from '@/editor/CardEditor/CardEditor';
import { BlockType } from '@/declarations/models/BlockType';
import { Status } from '@/declarations/models/Status';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SortSelector } from '@/components/SortSelector';
import { TagsAutocomplete } from '@/components/TagsAutocomplete';
import { Tag } from '@/declarations/models/Tag';
import { PageStatusCircle } from '@/components/PageStatusCircle';

type BlocksSortOptions = SortType.TITLE | SortType.UPDATED_AT;

const CardCategoryBadge: FC<{ category: string }> = ({ category }) => {
  return <Chip label={category} color='secondary' size='small' />;
};

export const CardsView: FC<ChildrenProp> = () => {
  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const selectedLocale = store?.state?.selectedSiteLanguage || 'no';
  const skins = store?.state?.selectedSiteSkins || ([] as Array<Skin>);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmDialog();
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const [query, setQuery] = useState<string>('');

  const [sortOption, setSortOption] = useState<SortOption>({ sortBy: SortType.CREATED_AT, order: SortDirection.DESC });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cards, setCards] = useState<Array<GenericMedia<Card>>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<{ start: number; rows: number }>({ start: 0, rows: 20 });

  const [selectedBlockType, setSelectedBlockType] = useState<BlockType.CARD | BlockType.PERSON>(BlockType.CARD);
  const [selectedCategories, setSelectedCategories] = useState<Array<Tag>>([]);
  const [allTags, , refreshTags] = useApi(() => Api.getAllTagsForSite(siteId || 0, {}), []);
  const categories = allTags.filter((tag) => tag.content?.isCardCategory === true && tag.type === TagType.CATEGORY);
  useEffect(() => {
    refreshTags();
    setSelectedCategories([]);
  }, [refreshTags, siteId]);

  const [cardId, setCardId] = useState<number>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState({} as unknown);

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const onAfterSave = () => {
    setForceRefresh({});
  };

  const handleItemDelete = async (item: GenericMedia<Card>) => {
    const id = Number(item.id);
    if (!Number.isNaN(id) && (await confirm(tComponents('MediaView.ConfirmDelete')))) {
      const [, error] = await Api.deleteBlock(siteId, id).fetch();
      if (error) enqueueSnackbar(error.message, { variant: 'error' });
      else enqueueSnackbar('Deleted item', { variant: 'success' });
      setForceRefresh({});
    }
  };

  const onItemClick = (item: GenericMedia<Card>) => {
    setCardId(Number(item.id));
    setEditModalOpen(true);
  };

  const onCreateCardClick = () => {
    setCardId(undefined);
    setEditModalOpen(true);
  };

  const getBackgroundColor = (item: Card): string => {
    const skin = skins.find((s) => s.class === item.content?.skin);
    return skin?.hex || Styles.Colors.WHITE;
  };

  const getTextColor = (item: Card): string | undefined => {
    const skin = skins.find((s) => s.class === item.content?.skin);
    return skin?.txt;
  };

  useEffect(() => {
    let unmounted = false;
    const ctx = Api.getBlocks(siteId, {
      ...page,
      categories: selectedCategories.map((cat) => cat.tag).join(','),
      order: sortOption.order,
      order_by: sortOption.sortBy as BlocksSortOptions,
      locale: selectedLocale,
      block_type: selectedBlockType || undefined,
      text_query: query,
    });
    setIsLoading(true);
    ctx
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          setTotalCount(res.totalCount || 0);
          setCards(
            res.items.map(
              (item) =>
                ({
                  id: String(item.id || 0),
                  title: '', // title is rendered by custom render function
                  url: item.content?.mediaobject?.url,
                  mimetype: item.content?.mediaobject?.mimetype,
                  source: item,
                } as GenericMedia<Card>),
            ),
          );
        }
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });

    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [page, query, selectedCategories, siteId, sortOption, forceRefresh, selectedLocale, selectedBlockType]);

  const renderListItemContent = (item: GenericMedia<Card>) => (
    <Grid container width='100%'>
      <Grid item xs={1}>
        <ListItemAvatar>
          <Avatar src={item.url || PlaceholderImage} variant='square' />
        </ListItemAvatar>
      </Grid>
      <Grid item xs={9} display='flex' flexDirection='column'>
        <Box display='flex'>
          <Typography variant='body1' fontWeight='bold'>
            {item.source?.title}
          </Typography>
          {item.source?.page?.title && (
            <Container ml={4}>
              <PageStatusCircle status={item.source.page.status} />
              <Typography
                variant='caption'
                color={item.source.page.status === Status.PUBLISHED ? 'green' : 'textSecondary'}>
                {item.source.page.title}
              </Typography>
            </Container>
          )}
        </Box>
        <Box>
          <Typography variant='body2' sx={noWrapTextStyle}>
            {item.source?.description}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        {item.source?.content?.category?.tag && <CardCategoryBadge category={item.source.content.category.tag} />}
      </Grid>
    </Grid>
  );

  const renderGridItemContent = (item: GenericMedia<Card>) => (
    <Container fullWidth column left gap={0}>
      <ButtonBase
        tabIndex={-1}
        sx={{
          width: '100%',
          display: 'block',
          border: `1px solid ${Styles.Colors.LIGHT_GREY}`,
          borderTop: 'none',
        }}
        onClick={() => onItemClick(item)}>
        <Box
          height='10em'
          overflow='hidden'
          sx={(theme) => ({
            backgroundColor: getBackgroundColor(item.source || {}),
            color:
              getTextColor(item.source || {}) ?? theme.palette.getContrastText(getBackgroundColor(item.source || {})),
            p: 2,
            textAlign: 'left',
          })}>
          <Typography variant='body1' fontWeight='bold'>
            {item.source?.title}
          </Typography>
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              lineClamp: 2,
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}>
            {item.source?.description}
          </Typography>
        </Box>
      </ButtonBase>
      <Container
        fullWidth
        left
        wrap
        p={1}
        sx={{
          backgroundColor: Styles.Colors.LIGHT_GREY,
          minHeight: '3em',
        }}>
        {item.source?.content?.category?.tag && <CardCategoryBadge category={item.source.content.category.tag} />}
        {item.source?.page?.title && (
          <Container ml={0}>
            <PageStatusCircle status={item.source.page.status} />
            <Typography
              variant='caption'
              color={item.source.page.status === Status.PUBLISHED ? 'green' : 'textSecondary'}>
              {item.source.page.title}
            </Typography>
          </Container>
        )}
        {!item.source.page_id && (
          <Container ml={0}>
            <Icon sx={{ marginLeft: '10px', width: '40px' }}>
              <MaterialSymbol name='link' />
            </Icon>
            <Typography variant='caption'>{(item.source.content?.url || '#').substring(0, 20)}..</Typography>
          </Container>
        )}
        <Button
          sx={{ marginLeft: 'auto' }}
          onClick={() => handleItemDelete?.(item)}
          endIcon={<MaterialSymbol name='delete' fill />}>
          {tCommon('delete')}
        </Button>
      </Container>
    </Container>
  );

  return (
    <Container p={6} pb={0} fullWidth fullHeight column gap={0} top>
      <FullScreenModal modalOpen={editModalOpen}>
        <CardEditor
          siteId={siteId}
          cardId={cardId}
          onCloseEditor={() => setEditModalOpen(false)}
          skins={skins}
          categories={categories}
          onAfterSave={onAfterSave}
          defaultLocale={selectedLocale}
        />
      </FullScreenModal>
      <ToolbarHeader
        renderSearchField={({ query: searchBarQuery, onQueryChange: onSearchBarQueryChange }) => (
          <TagsAutocomplete
            tagType={TagType.CATEGORY}
            siteId={siteId}
            query={searchBarQuery}
            onQueryChange={onSearchBarQueryChange}
            tags={selectedCategories}
            onTagsChange={setSelectedCategories}
          />
        )}
        query={query}
        onQueryChange={setQuery}
        heading={
          <Typography variant='h3' component='h1' fontSize={30}>
            {tComponents('CardsView.Title')}
          </Typography>
        }
        topRight={
          <Box>
            <Button
              variant='contained'
              color='success'
              startIcon={<MaterialSymbol name='add' />}
              onClick={onCreateCardClick}
              type='button'>
              {tComponents('CardsView.CreateCard')}
            </Button>
          </Box>
        }
        bottomRight={
          <Box display='flex' flexWrap='wrap' alignItems='center' flexGrow={1}>
            <Typography fontWeight='bold' mr={2}>
              {tComponents('CardsView.BlockTypeLabel')}
            </Typography>
            <RadioGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
              value={selectedBlockType}
              onChange={(e) => setSelectedBlockType(e.target.value as BlockType.CARD | BlockType.PERSON)}>
              {[BlockType.CARD, BlockType.PERSON].map((blockType) => (
                <FormControlLabel
                  key={blockType || 'all'}
                  control={<Radio role='radio' aria-label={tComponents(`CardsView.BlockType.${blockType}`)} />}
                  value={blockType}
                  label={tComponents(`CardsView.BlockType.${blockType}`)}
                />
              ))}
            </RadioGroup>
          </Box>
        }
        boxProps={{ alignSelf: 'stretch' }}
      />

      {selectedBlockType === BlockType.PERSON && (
        <Box display='flex' flexWrap='wrap' alignItems='center' width='100%' mt={2}>
          <Box width='100%' mt={2}>
            <Box
              display='inline-block'
              p={2}
              borderRadius={1}
              border={`1px solid ${Styles.Colors.MEDIUM_BLUE}`}
              bgcolor={Styles.Colors.LIGHT_BLUE}>
              <div dangerouslySetInnerHTML={{ __html: tComponents(`CardsView.PersonsInfo`) }} />
            </Box>
          </Box>
        </Box>
      )}

      <MediaList
        customHeaderElement={
          <SortSelector
            availableOptions={[SortType.TITLE, SortType.CREATED_AT]}
            setSortOption={setSortOption}
            sortOption={sortOption}
          />
        }
        contentOverflow='visible'
        renderListItemContent={renderListItemContent}
        renderGridItemContent={renderGridItemContent}
        headerSx={{}}
        onPageChange={(_, start, rows) => handlePageChanged({ start, rows })}
        isLoading={isLoading}
        items={cards}
        page={page}
        totalItemCount={totalCount}
        onItemClick={(item) => onItemClick(item)}
        isSelectable={false}
        defaultSelectedViewType={ViewType.GRID}
      />
    </Container>
  );
};

export default CardsView;
