import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../components/Container';
import { M24MediaModel } from '../../declarations/models/M24MediaModel';
import Loader from '../../components/Loader';
import { MediaResourceType } from '../../declarations/models/MediaResourceType';
import Styles from '../../assets/js/Styles';
import { formatAPITimestamp } from '../../utils/dates';
import { User } from '../../declarations/models/User';
import MediaEditorPreviewUsageLinks from './MediaEditorPreviewUsageLinks';

export interface MediaEditorPreviewProps {
  media?: M24MediaModel;
  uploadedBy?: User;
  siteId?: number;
}

const flexStyling = {
  flex: 1,
  justifySelf: 'stretch',
  maxWidth: '100%',
};

const infoStyling = {
  padding: 2,
  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  minWidth: '240px',
  maxWidth: '100%',
  backgroundColor: Styles.Colors.FINDER_M24_PRIMARY_HEADER_BACKGROUND,
};

export const MediaEditorPreview: FC<MediaEditorPreviewProps> = ({ media, uploadedBy, siteId }) => {
  const { t: tComp } = useTranslation('components');

  if (!media) {
    return (
      <Container fullWidth fullHeight>
        <Loader />
      </Container>
    );
  }

  const handleOpenNewTab = () => {
    let url: string;
    if (media.play_urls) {
      url =
        Object.entries(media.play_urls)
          .map(([, src]) => src)
          .find((src) => !!src) || '';
    } else {
      url = media.url;
      if (media.type === MediaResourceType.IMAGE) {
        url = `${media.url}?dimension=max&mediaType=image/jpeg`;
      }
    }
    window.open(url, '_blank');
  };

  const handleDownload = () => {
    let url: string;

    if (media.type === MediaResourceType.IMAGE) {
      url = `${media.url}?dimension=original&filename=${media.filename}`;
      window.open(url, '_blank');
    } else {
      alert('Download from viewer (above)');
    }
    // fetch(media.url)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', media.filename);
    //     link.click();
    //   });
  };

  let imgMimeType = '';
  if (media?.mimetype?.includes('png')) {
    imgMimeType = `?mediaType=${media?.mimetype}`;
  }

  const getMediaPlayer = () => {
    switch (media.type) {
      case MediaResourceType.IMAGE:
      case MediaResourceType.ANIMATION:
        return (
          <img
            src={`${media.url}${imgMimeType}`}
            title={media.filename}
            style={{ maxHeight: '100%', maxWidth: '100%', borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}
            alt={media.content?.alttext}
          />
        );
      case MediaResourceType.VIDEO:
        return (
          <video
            controls
            title={media.filename}
            style={{ width: '100%', maxHeight: '100%', borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}>
            <track kind='captions' src={media.content?.alttext} />
            {media.play_urls &&
              Object.entries(media.play_urls).map(([fileType, url]) => (
                <source key={fileType} src={url} type={`video/${fileType}`} />
              ))}
          </video>
        );
      case MediaResourceType.AUDIO:
        return (
          <video
            controls
            style={{
              height: '54px',
              width: '100%',
              backgroundColor: Styles.Colors.STRONG_GREEN,
              borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            }}>
            <track kind='captions' src={media.content?.alttext} />
            {media.play_urls &&
              Object.entries(media.play_urls).map(([fileType, url]) => (
                <source key={fileType} src={url} type={`audio/${fileType}`} />
              ))}
          </video>
        );
      case MediaResourceType.DOCUMENT:
        if (media.mimetype?.includes('pdf')) {
          return <iframe title={media.title} src={media.url} width='100%' height='100%' />;
        }
        return <div />;
    }
  };

  return (
    <Container fullWidth fullHeight column top gap={2} pl={2} sx={{ overflowY: 'auto' }}>
      <Container
        fullWidth
        column
        sx={{
          height: media.type === MediaResourceType.DOCUMENT ? '100%' : 'fit-content',
          maxHeight: '50vh',
        }}>
        {getMediaPlayer()}
      </Container>
      <Container gap={2} top fullWidth wrap sx={{ maxWidth: '100%' }}>
        <Container column gap={2} top sx={flexStyling}>
          <Container fullWidth sx={infoStyling}>
            {media.type === MediaResourceType.IMAGE && (
              <Tooltip title={tComp('MediaEditor.DownloadFile')} arrow>
                <IconButton onClick={handleDownload}>
                  <MaterialSymbol name='download' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={tComp('MediaEditor.OpenInNewTab')} arrow>
              <IconButton onClick={handleOpenNewTab}>
                <MaterialSymbol name='open_in_new' />
              </IconButton>
            </Tooltip>
          </Container>
          <Container fullWidth left column sx={infoStyling}>
            <Typography maxWidth='100%' noWrap>
              <b>{tComp(`MediaView.Filename`)}: </b>
              {media.filename}
            </Typography>
            {media.mimetype && (
              <Typography maxWidth='100%' noWrap>
                <b>{tComp(`MediaView.Filetype`)}: </b>
                {media.mimetype}
              </Typography>
            )}
            <Typography maxWidth='100%' noWrap>
              <b>{tComp(`MediaView.MediaId`)}: </b>
              {media.id}
            </Typography>
            <Typography maxWidth='100%' noWrap>
              <b>{tComp(`MediaView.Created_at`)}: </b>
              {formatAPITimestamp(media.created_at, 'datetime')}
            </Typography>
            {uploadedBy?.name && (
              <Typography maxWidth='100%' noWrap>
                <b>{tComp(`MediaView.Created_by`)}: </b>
                {uploadedBy?.name}
              </Typography>
            )}
          </Container>
        </Container>
        <Container sx={{ ...infoStyling, ...flexStyling }}>
          <MediaEditorPreviewUsageLinks mediaUsages={media.media_usages} siteId={siteId} />
        </Container>
      </Container>
    </Container>
  );
};

export default MediaEditorPreview;
