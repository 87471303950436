/* istanbul ignore file */
import { BaseBlock } from './BaseBlock';
import { BlockType } from '../BlockType';
import { LinkExternal } from '../LinkExternal';
import { Tag } from '../Tag';
import { Page } from '../Page';
import { CardSize, CardMediaRatio } from '../CardSize';
import { Card } from '../Card';
import { Person } from '../Person';

export enum CardModuleView {
  GRID = 'grid',
  GRID_NARROW = 'grid-narrow',
  GRID_WIDE = 'grid-wide',
  GRID_FULL = 'grid-full',
  LIST = 'list',
  LIST_NARROW = 'list-narrow',
  LIST_WIDE = 'list-wide',
  LIST_FULL = 'list-full',
  HORIZONTAL = 'horizontal',
  ARTICLES = 'articles',
}

export interface CardModuleBlock extends BaseBlock {
  type: BlockType.CARD_SEARCH;
  categories?: string;
  category?: Array<unknown>; // TODO
  filterCategories?: Array<Tag>;
  filterTags?: Array<Tag>;
  fixedCardHeight?: string;
  groupBy?: false | 'alpha';
  includeGlobalListSearch?: boolean;
  includeListSearch?: boolean;
  includeSelf?: boolean;
  items: Array<Card | Person>;
  links?: Array<LinkExternal | Page>;
  listSearchText?: string;
  media__type: '';
  order?: 'desc' | 'asc';
  orderBy?: 'date' | 'title' | 'event';
  rows?: string;
  searchFromPage?: Page;
  searchItems?: Array<unknown>; // TODO
  settings: {
    metadataformat: string;
    size: CardSize | '';
    mediaRatio: CardMediaRatio | '';
  };
  structure__search?: string;
  entity_subtype?: 'article__person' | 'article__card' | '';
  tags?: string;
  version?: string;
  view: CardModuleView;
  // comma separated list
  site_ids?: string;
  skin?: string;
  gridSize?: string;
  gridMobileBehaviour?: string;
  reverseOrder?: boolean;
}
