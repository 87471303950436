import React, { FC, ReactNode } from 'react';
import CustomIcon from '@/components/CustomIcon';
import { BlockType } from '../declarations/models/BlockType';
import { MaterialSymbol } from './MaterialSymbol';

export interface BlockIconProps {
  blockType: BlockType;
}

const BLOCK_ICONS: { [block in BlockType]: ReactNode | undefined } = {
  [BlockType.ADD_CARD]: <MaterialSymbol name='crop_portrait' />,
  [BlockType.ARTICLE_TITLE]: <MaterialSymbol name='title' />,
  [BlockType.ATTACHMENT]: <MaterialSymbol name='file_present' />,
  [BlockType.BANNER]: <CustomIcon name='banner' />,
  [BlockType.BYLINE]: <MaterialSymbol name='person' />,
  [BlockType.CALENDAR]: <MaterialSymbol name='date_range' />,
  [BlockType.CALENDAR_NAVIGATOR]: <MaterialSymbol name='update' />,
  [BlockType.CARD]: <MaterialSymbol name='crop_portrait' />,
  [BlockType.CARD_SEARCH]: <CustomIcon name='card' />,
  [BlockType.DATELINE]: <MaterialSymbol name='event' />,
  [BlockType.DM_AUTO]: <CustomIcon name='dm_auto' sx={{ height: 20, width: 39 }} />,
  [BlockType.DM_MANUAL]: <CustomIcon name='dm' sx={{ height: 20, width: 39 }} />,
  [BlockType.DM_SINGLE]: <CustomIcon name='dm' sx={{ height: 20, width: 39 }} />,
  [BlockType.FORM]: <MaterialSymbol name='assignment' />,
  [BlockType.KULTUR_PUNKT]: undefined,
  [BlockType.DIGITALT_MUSEUM]: undefined,
  [BlockType.KP]: <CustomIcon name='kulturpunkt' sx={{ height: 17, width: 15 }} />,
  [BlockType.KP_OWNER]: <CustomIcon name='kulturpunkt' sx={{ height: 17, width: 15 }} />,
  [BlockType.MAP]: <MaterialSymbol name='map' />,
  [BlockType.MAP_V2]: <MaterialSymbol name='map' />,
  [BlockType.MEDIA]: <MaterialSymbol name='image' fill />,
  [BlockType.MEMBER]: <MaterialSymbol name='account_circle' />,
  [BlockType.OPENING_HOURS]: <MaterialSymbol name='schedule' />,
  [BlockType.PAGE_LIST_AUTO]: <CustomIcon name='page_listing_auto' />,
  [BlockType.PAGE_LIST_MANUAL]: <CustomIcon name='page_listing_manual' />,
  [BlockType.PERSON]: <MaterialSymbol name='badge' />,
  [BlockType.PRODUCT_STATUS]: <MaterialSymbol name='bar_chart' />,
  [BlockType.PROGRAM]: <MaterialSymbol name='event_note' />,
  [BlockType.QUOTE]: <MaterialSymbol name='format_quote' />,
  [BlockType.FRONTPAGE]: <CustomIcon name='banner' />,
  [BlockType.REFERENCES]: <MaterialSymbol name='menu_book' />,
  [BlockType.RELATIONS]: <MaterialSymbol name='toc' />,
  [BlockType.SEARCH]: <MaterialSymbol name='search' />,
  [BlockType.SHARING]: <MaterialSymbol name='share' />,
  [BlockType.TABLE]: <MaterialSymbol name='view_list' />,
  [BlockType.TEXT]: <MaterialSymbol name='subject' />,
  [BlockType.TICKET]: <MaterialSymbol name='book_online' />,
  [BlockType.TICKET_VALIDATION]: <MaterialSymbol name='center_focus_weak' />,
  [BlockType.TIMELINE]: <MaterialSymbol name='timeline' />,
  [BlockType.WIDGET]: <MaterialSymbol name='code_blocks' />,
  [BlockType.HEADER_MENU]: undefined,
  [BlockType.FOOTER_ADDRESS]: undefined,
  [BlockType.FOOTER_INFO]: undefined,
  [BlockType.FOOTER_MENU]: undefined,
  [BlockType.FOOTER_SOCIAL]: undefined,
  [BlockType.EMPLOYEES_AUTO]: <MaterialSymbol name='badge' />,
  [BlockType.EMPLOYEES_MANUAL]: <MaterialSymbol name='badge' />,
  [BlockType.EVENTS_AUTO]: <CustomIcon name='calendar_event_auto' />,
  [BlockType.EVENTS_MANUAL]: <CustomIcon name='calendar_event' />,
};

export const BlockIcon: FC<BlockIconProps> = ({ blockType }) => {
  return <>{BLOCK_ICONS[blockType]}</>;
};

export default BlockIcon;
